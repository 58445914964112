import Container from "../Container/Container";
import css from "./HeroSection.module.scss";
import image1 from "../../assets/Images/concert-hands-up-dark-party.png";
import { useSelector } from "react-redux";
import { VENUE_MANAGER } from "../../constants/data";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const { user } = useSelector(state => state.user);
  const { usertype } = user ?? {};
  const navigate = useNavigate();
  const url = (usertype === VENUE_MANAGER ? "/shows/s" : "/venues/s");
  const handleExplore = () => {
    navigate(url);
  }
  return (
    <div>
      <div className={css.ImageContent}>
        <img src={image1} alt="" />
        <Container>
          <div className={css.heroWrapper}>
            <div>
              <h1 className={css.titleText}>
                <span>Dart è il software gestionale rivolto a teatri, agenzie e artisti per collegarsi e gestire l’intero processo di booking semplicemente</span>
              </h1>
              <div>
                <button className={css.heroButton} onClick={handleExplore}>Explore</button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* services section  */}
      {/* </Container> */}
    </div>
  );
};

export default HeroSection;
